<script setup>
import { useStore } from "vuex"
import Notifications from '~/components/Notifications.vue'
import Header from '~/components/Header.vue'
import Menu from '~/components/Menu.vue'
import MobileMenu from '~/components/MobileMenu.vue'
import Footer from '~/components/Footer.vue'
import RequestDemo from '~/components/RequestDemo.vue'

const store = useStore()

const showRequestModal = computed(() => store.state.common.showRequestModal)

let showMobileMenu = ref(false)

useHead({
	htmlAttrs: {
		class: computed(() => {
			if (showMobileMenu.value || showRequestModal.value) return 'overflow-hidden'
			
			return ''
		})
	},
	bodyAttrs: {
		class: computed(() => {
			if (showMobileMenu.value || showRequestModal.value) return 'overflow-hidden'
			
			return ''
		})
	}
})
</script>

<template>
	<div>
        <div class="page">
            <div class="page__header">
                <Header @showMobileMenu="showMobileMenu = true" />
            </div>
            <aside class="page__menu">
                <Menu />
            </aside>
			<div class="page__wrapper">
				<main class="page__content">
					<slot />
				</main>
				<div class="page__footer">
					<Footer />
				</div>
			</div>
        </div>
		<Notifications />
		<RequestDemo />
		<div class="page__mobile" :class="{ active: showMobileMenu }">
            <MobileMenu @closeMobileMenu="showMobileMenu = false" />
        </div>
    </div>
</template>

<style lang="scss">
.page {
    min-height: 100vh;
    background: #F7F7F9;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
	&.overflow-hidden {
		overflow: hidden;
	}
    &__header {
        position: fixed;
        top: 0;
        right: 0;
        width: calc(100% - 200px);
		background: transparent;
        height: 64px;
        border-top-left-radius: 40px;
		z-index: 2;

        .overflow-hidden & {
            z-index: 0;
        }
    }
    &__menu {
        width: 200px;
		min-width: 200px;
        min-height: 100vh;
    }
	&__wrapper {
		position: relative;
		width: calc(100% - 200px);
		z-index: 0;
	}
    &__content {
        min-height: 100vh;
        width: 100%;
        background: white;
        border-top-left-radius: 40px;
        border-bottom-left-radius: 40px;
    }
	&__mobile {
        display: none;
    }
}

@media screen and (max-width: 767.98px) {
	.page {
		&__header {
			width: 100%;
			border-top-left-radius: 0;
			height: 56px;
		}
		&__menu {
			display: none;
		}
		&__wrapper {
			width: 100%;
		}
		&__mobile {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            display: block;
            opacity: 0;
            visibility: hidden;
            height: 0;
            transition: opacity 0.2s ease;
			z-index: 3;
            &.active {
                opacity: 1;
                visibility: visible;
                height: 100%;
            }
        }
	}
}
</style>